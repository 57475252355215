import { catchError, from, Observable, switchMap, throwError } from 'rxjs';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  authService = inject(AuthService)

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Get the auth token from the service.
    const authToken = this.authService.getAuthenticationTokenFromLocalStorage();
    if (authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }


    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        // If token has expired, refresh the session before retrying
        if (err.status === 401) {
          return this.reAuthenticate().pipe(
            switchMap(() => {
              // Update the request headers with the new token
              const authToken = this.authService.getAuthenticationTokenFromLocalStorage();
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${authToken}`,
                },
              });

              // Retry the original request with the updated headers
              return next.handle(request);
            })
          );
        }

        return throwError(() => err);
      })
    ) as Observable<HttpEvent<unknown>>;
  }

  reAuthenticate(): Observable<any> {
    return from(this.authService.refreshSession());
  }

}
