import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Route } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { CaptureConsole } from '@sentry/integrations';

import { AppComponent } from './app/app.component';
import { TokenInterceptor } from './app/shared/data-access/auth/token.interceptor';
import { environment } from './environments/environment';

// Initialize Sentry project
Sentry.init({
  dsn: "https://efd1079bdb20a93bab05ea82370e5c5e@o1059346.ingest.sentry.io/4506695349633024",
  environment: environment.environment,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
    // Registers the Capture Console integration,
    // which allow to report console.[level] in Sentry
    new CaptureConsole({
      levels: ['error']
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const appRoutes: Route[] = [
  {
    path: "",
    loadComponent: () => import("./app/layout/layout.component").then((m) => m.LayoutComponent),
    loadChildren: () => import("./app/layout/layout.routes").then((m) => m.routes),
  },
  {
    path: 'sign-in', loadComponent: () =>
      import('./app/auth/sign-in.component').then(
        (m) => m.SignInComponent
      ),
  },
  {
    path: '**', loadComponent: () =>
      import('./app/shared/ui/error/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
];

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(
      withInterceptorsFromDi(),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor, multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
  ]
}).catch((err) =>
  console.log(err)
);
