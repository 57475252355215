

import { inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';



export const LOCAL_STORAGE = new InjectionToken<Storage>(
    "window local storage object",
    {
        providedIn: "root",
        factory: () => {
            return inject(PLATFORM_ID) === "browser"
                ? window.localStorage
                : ({} as Storage);
        },
    }
);

@Injectable({
    providedIn: "root",
})
export class StorageService {
    storage = inject(LOCAL_STORAGE);

    loadFromStorage<T>(key: string): T | null {
        const item = this.storage.getItem(key);
        return item ? JSON.parse(item) as T : null;
    }

    saveToStorage<T>(key: string, item: T | null): void {
        this.storage.setItem(key, JSON.stringify(item));
    }

}