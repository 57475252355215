import { Amplify, Auth } from 'aws-amplify';
import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { environment } from '../../../../environments/environment';
import { StorageService } from '../storage/storage.service';

export interface User {
  name: string;
  email: string;
  username: string;
}

export interface CognitoUser {
  email: string;
  email_verified: string;
  name: string;
  sub: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private storageService = inject(StorageService);

  isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentUser: any; // Change the type according to your user object

  constructor() {
    Amplify.configure({
      Auth: {
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
      },

      oauth: {
        domain: environment.oauthDomain,
        responseType: 'code',
        scope: ['phone', 'email', 'profile', 'openid'],
        redirectSignIn: environment.oauthRedirectSignIn,
        redirectSignOut: environment.oauthRedirectSignOut,
      },
    });
  }

  setCurrentUser(user: any): void {
    this.currentUser = user;
  }

  getCurrentUser(): any {
    return this.currentUser;
  }

  public signIn(email: string, password: string): Promise<any> {
    return Auth.signIn(email, password)
      .then(() => {
        this.isAuthenticatedSubject.next(true);
      });
  }

  public handleFederatedLogin(provider: string) {
    const cognitoProvider = provider as CognitoHostedUIIdentityProvider;
    return Auth.federatedSignIn({ provider: cognitoProvider })
  }

  // public async handleFederatedLogin(provider: string) {
  //   const cognitoProvider = provider as CognitoHostedUIIdentityProvider;

  //   try {
  //     const cred = await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  //     // Handle successful sign-in if needed
  //     console.log("cred 2", cred)
  //   } catch (error) {
  //     // Handle the error here
  //     console.error("Federated sign-in error:", error);
  //   }
  // }

  public signOut(): Promise<any> {
    return Auth.signOut()
      .then(() => {
        this.isAuthenticatedSubject.next(false);
        console.log('signed out')
      });
  }

  async isUserAuthenticated(): Promise<boolean> {
    try {
      // Use AWS Amplify's Auth.currentAuthenticatedUser method to check if the user is authenticated
      const user = await Auth.currentAuthenticatedUser();
      // If the user is authenticated, the promise will resolve successfully
      this.isAuthenticatedSubject.next(true);
      return true;
    } catch (error) {
      // If the user is not authenticated, an error will be thrown
      return false;
    }
  }

  // Get the current authenticated user's information
  public getUser(): Promise<CognitoUser> {
    return Auth.currentUserInfo();
  }

  // saves the current user's information to local storage
  async saveUserToStorage() {
    try {
      const session = await Auth.currentSession();
      const idTokenPayload = session.getIdToken().payload;
      const user: User = {
        username: idTokenPayload['cognito:username'],
        name: idTokenPayload.name,
        email: idTokenPayload.email,
      }

      this.storageService.saveToStorage<User>("user", user);
    } catch (error) {
      console.error('Error getting user info:', error);
    }
  }

  async refreshSession() {
    try {
      // Auth.currentSession() checks if token is expired and refreshes automatically if needed
      const currentSession = await Auth.currentSession();
      console.log('Refreshed Token', currentSession);
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  }

  getAuthenticationToken(): Promise<string> {
    return Auth.currentSession().then(data => {
      return data.getAccessToken().getJwtToken()
    }).catch(err => {
      console.log(err)
      return '';
    });
  }

  getAuthenticationTokenFromLocalStorage() {
    const prefix = `CognitoIdentityServiceProvider.${environment.userPoolWebClientId}`;
    const user = localStorage.getItem(`${prefix}.LastAuthUser`);
    const token = localStorage.getItem(`${prefix}.${user}.accessToken`);

    return token;
  }

}
