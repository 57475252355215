import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  selector: 'attune-webapps-root',
  template: `<router-outlet></router-outlet>`,
  styles: [],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class AppComponent {
  title = 'portal-v2';
}
